nav {
    color: rgb(255, 244, 244);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;
    height: 17vh;
    position: relative;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  ul {
    display: flex;
    list-style: none;
  }
  
  ul li {
    display: inline-block;
    margin: 0 1rem;
  }
  
  ul li a {
    text-decoration: none;
    display: block;
    position: relative;
    color: rgb(255, 244, 244);
  }
  
  ul li a::after {
    content: "";
    position: absolute;
    height: 0.1rem;
    width: 0%;
    left: 0;
    top: 1.8rem;
    background: white;
    transition: 0.3s linear;
  }
  
  ul li a:hover::after {
    width: 100%;
  }
  
  ul li a.clicked::after {
    width: 100%;
  }
  
  ul li a:hover {
    font-weight: bold;
  }
  
  .toggle-button {
    display: none;
    font-size: 1.8rem;
  }
  
  @media screen and (max-width: 600px) {
    nav {
      padding: 0 3rem;
    }
  }
  @media screen and (max-width: 500px) {
    .toggle-button {
      display: block;
    }
    ul {
      flex-direction: column;
      position: fixed;
      top: 8vh;
      background: #252525;
      width: 100%;
      height: 100%;
      left: -1000px;
      justify-content: flex-start;
      align-items: center;
    }
  
    ul li {
      padding: 2rem 0;
      font-size: 1.2rem;
    }
  
    ul.active {
      left: 0;
    }
  }