.main-content {
    font-family: Noto Kufi Arabic;
    text-align: center;
    color: white;
  }

  .uc-img {
    animation-name: up-and-down;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes up-and-down {
    to {
      transform: translateY(-20px);
    }
  }