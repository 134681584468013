.Home-content {
    font-family: Noto Kufi Arabic;
    text-align: center;
    color: white;
  }

  .btn {
      width: 111px;
      outline: none;
      display: block;
      border: solid 1px white;
      text-decoration: none;
      line-height: 1;
      padding: 16px 30px;
      border-radius: 30px;
      background: transparent;
      color: white;
      cursor: pointer;
      transition: all 0.3s linear;
      text-align: center;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      animation-name: up-and-down;
      animation-duration: 1.3s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
  }

  .btn:hover{
      background: white;
      color: #047A9E;
  }

@keyframes up-and-down {
  to {
    transform: translateY(-20px);
  }
}
  