@import url(https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@200;300;400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Noto Kufi Arabic', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(/static/media/bg.da31a841.svg) no-repeat center center fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.main-footer {
    color: white;
    text-align: center;
    padding-top: 1em;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 11px;
  }
.Home-content {
    font-family: Noto Kufi Arabic;
    text-align: center;
    color: white;
  }

  .btn {
      width: 111px;
      outline: none;
      display: block;
      border: solid 1px white;
      text-decoration: none;
      line-height: 1;
      padding: 16px 30px;
      border-radius: 30px;
      background: transparent;
      color: white;
      cursor: pointer;
      transition: all 0.3s linear;
      text-align: center;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      animation-name: up-and-down;
      animation-duration: 1.3s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
  }

  .btn:hover{
      background: white;
      color: #047A9E;
  }

@keyframes up-and-down {
  to {
    transform: translateY(-20px);
  }
}
  
nav {
    color: rgb(255, 244, 244);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;
    height: 17vh;
    position: relative;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  ul {
    display: flex;
    list-style: none;
  }
  
  ul li {
    display: inline-block;
    margin: 0 1rem;
  }
  
  ul li a {
    text-decoration: none;
    display: block;
    position: relative;
    color: rgb(255, 244, 244);
  }
  
  ul li a::after {
    content: "";
    position: absolute;
    height: 0.1rem;
    width: 0%;
    left: 0;
    top: 1.8rem;
    background: white;
    transition: 0.3s linear;
  }
  
  ul li a:hover::after {
    width: 100%;
  }
  
  ul li a.clicked::after {
    width: 100%;
  }
  
  ul li a:hover {
    font-weight: bold;
  }
  
  .toggle-button {
    display: none;
    font-size: 1.8rem;
  }
  
  @media screen and (max-width: 600px) {
    nav {
      padding: 0 3rem;
    }
  }
  @media screen and (max-width: 500px) {
    .toggle-button {
      display: block;
    }
    ul {
      flex-direction: column;
      position: fixed;
      top: 8vh;
      background: #252525;
      width: 100%;
      height: 100%;
      left: -1000px;
      justify-content: flex-start;
      align-items: center;
    }
  
    ul li {
      padding: 2rem 0;
      font-size: 1.2rem;
    }
  
    ul.active {
      left: 0;
    }
  }
.main-content {
    font-family: Noto Kufi Arabic;
    text-align: center;
    color: white;
  }

  .uc-img {
    animation-name: up-and-down;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes up-and-down {
    to {
      transform: translateY(-20px);
    }
  }
.main-content {
    font-family: Noto Kufi Arabic;
    text-align: center;
    color: white;
  }

  .uc-img {
    animation-name: up-and-down;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes up-and-down {
    to {
      transform: translateY(-20px);
    }
  }
.main-content {
    font-family: Noto Kufi Arabic;
    text-align: center;
    color: white;
  }

  .uc-img {
    animation-name: up-and-down;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes up-and-down {
    to {
      transform: translateY(-20px);
    }
  }
.main-content {
    font-family: Noto Kufi Arabic;
    text-align: center;
    color: white;
  }

  .uc-img {
    animation-name: up-and-down;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes up-and-down {
    to {
      transform: translateY(-20px);
    }
  }
